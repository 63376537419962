import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: 'Home',
		layout: "dashboard",
		redirect: '/dashboard',
		meta: { 
			zh_name: 'AI问答'
		}
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		layout: "dashboard",
		component: () => import('../views/Dashboard.vue'),
		meta: { 
			zh_name: 'AI问答'
		}
	},
	{
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Sign-In.vue'),
		meta: { 
			zh_name: '登陆'
		}
	},
	{
		path: '/sign-up',
		name: 'Sign-In',
		component: () => import('../views/Sign-Up.vue'),
		meta: { 
			zh_name: '忘记密码'
		}
	},
	{
		path: '/tran',
		name: 'Tran',
		layout: "dashboard",
		component: () => import('../views/Tran.vue'),
		meta: { 
			zh_name: 'AI翻译'
		}
	}
]

function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

export default router
