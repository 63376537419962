<template>

	<a-layout-header>
		<div class="header-col header-brand">
			<h6>Winndoo Dashboard</h6>

		</div>
	</a-layout-header>

</template>

<script>
	export default ({
		data() {
			return {
			}
		},
	})
</script>