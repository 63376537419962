<template>
	
	<a-layout-footer>
		<a-row type="flex">
		</a-row>
	</a-layout-footer>

</template>

<script>

	export default ({
		data() {
			return {
			}
		},
	})

</script>
