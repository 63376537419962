<template>
	<!-- Main Sidebar -->
	<a-layout-sider
		collapsible
		class="sider-primary"
		breakpoint="lg"
		collapsed-width="0"
		width="250px"
		:collapsed="sidebarCollapsed"
		@collapse="$emit('toggleSidebar', ! sidebarCollapsed)"
		:trigger="null"
		:class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
		theme="light"
		>
			<div>
				<a-button  block :disabled="new_disabled" @click="createRoom">
					创建聊天(最多10个)
				</a-button>
				<a-modal
					title="选择房间类型"
					:visible="visible"
					:confirm-loading="confirmLoading"
					cancelText="取消"
					okText="创建"
					@ok="handleOk"
					@cancel="handleCancel"
				>
				<div>
					<a-select  style="width: 120px"  @change="handleChange" :default-value="1">
						<a-select-option v-for="(item) in room_types" :key="item.id" :value="item.id">
							{{item.type_name}}
						</a-select-option>
					</a-select>
				</div>
				<div>
					<a-input v-model="room_name" style="width: 120px;margin-top: 10px;" />
				</div>
				</a-modal>
			</div>
			<hr>
			<!-- Sidebar Navigation Menu -->
			<a-menu theme="light" mode="inline">
				<a-menu-item   v-for="(value,index) in rooms" :key="index" :style="{marginBottom: '20px'}">  
					<router-link v-if="index == 0" @click.native="initRoom(value.id)" :to="{path:'/dashboard',query:{room_id:value.id,room_type:value.room_type_id}}">
						<span class="icon">
							<img :src="value.room_type.type_avatar" style="height: 30px;"/>
							<!-- <a-icon type="aliwangwang" theme="outlined" :style="{fontSize: '20px', marginRight: '0'}" /> -->
						</span>
						<span class="label">{{ !value.room_name ? '新的聊天' : value.room_name}} <span v-if="value.id == current_id">[ID:{{ value.id }}]</span></span>
					</router-link>
					<a-popconfirm
						title="是否删除该房间?"
						placement="right" 
						ok-text="Yes"
						cancel-text="No"
						v-if="index > 0"
						@confirm="deleteRoom(value.id)"
					>
						<router-link @click.native="initRoom(value.id)" :to="{path:'/dashboard',query:{room_id:value.id,room_type:value.room_type_id}}">
							<span class="icon">
								<!-- <a-icon type="aliwangwang" theme="outlined" :style="{fontSize: '20px', marginRight: '0'}" /> -->
								<img :src="value.room_type.type_avatar" style="height: 30px;"/>
							</span>
							<span class="label">{{ !value.room_name  ? '新的聊天' : value.room_name}}     <span v-if="value.id == current_id">[ID:{{ value.id }}]</span></span>
						</router-link>
  					</a-popconfirm>
				</a-menu-item>
			</a-menu>
			<!-- / Sidebar Navigation Menu -->

			<!-- Sidebar Footer -->
			<div class="aside-footer" style="padding-top: 0;">
				<div class="footer-box">
					<h6>{{userName}}</h6>
					<p>问答剩余[ {{ use_count }} ]次</p>
					<!-- <a-popconfirm
						title="确定要登出当前账号吗?"
						placement="right"
						ok-text="Yes"
						cancel-text="No"
						@confirm="logout"
					>
						<a-button type="primary" block target="_blank">
							登出
						</a-button>
					</a-popconfirm> -->
				</div>
			</div>

			<router-link to="/tran">
				<a-button type="primary" block >
					<a-icon type="file-text" />
					AI 翻译
				</a-button>
			</router-link>

	</a-layout-sider>
	<!-- / Main Sidebar -->

</template>
<style scoped>
.layout-dashboard .ant-layout-sider.sider-primary{
    margin-top: 10px;
}
.ant-menu-item a{
	background-color: #FFFFFF;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
}
.icon{
	background-color: #FFFFFF !important;
}
.label{
	font-weight: 600 !important;
}
</style>
<script>
	import axios from 'axios'
	export default ({
		props: {
			// Sidebar collapsed status.
			sidebarCollapsed: {
				type: Boolean,
				default: false,
			},
			
			// Main sidebar color.
			sidebarColor: {
				type: String,
				default: "primary",
			},
			
			// Main sidebar theme : light, white, dark.
			sidebarTheme: {
				type: String,
				default: "light",
			},
			clearMessage: {
				type: Function,
				default: null
			}
		},
		data() {
			return {
				room_name:'新的聊天',
				visible:false,
				confirmLoading:false,
				use_count:0,
				new_disabled:false,
				rooms:[],
				current_id:0,
				userName:'',
				routeName:'',
				room_types:[],
				select_type:1,
				type_avatar_1:"http://midjourney-prompt.oss-cn-beijing.aliyuncs.com/midjourney-prompt/pic/564794e4-a5e2-4f66-8cb5-9cd49486bf7f.png",
				type_avatar_2:"http://midjourney-prompt.oss-cn-beijing.aliyuncs.com/midjourney-prompt/pic/016cb75c-26aa-4d06-975d-6565f2125a28.png",
				type_avatar_3:"https://tradetm.zbjimg.com/tm/ca/7f/8a/38528797.jpg"
			}
		},
		methods:{
			handleChange(value){
				this.select_type = value
			},
			showModal() {
				this.visible = true;
			},
			handleOk(e) {
				if(this.room_name == '') return
				this.confirmLoading = true;
				this.new_disabled = true
				let _that = this
				let user_info = localStorage.getItem('user_info')
				if(!user_info) return
				user_info = JSON.parse(user_info)
				axios.post(
					'http://ai.tool.winndoo.cn/api/rooms', 
					{
						user_id:user_info.user_id,
						method:"add",
						room_type_id:this.select_type,
						room_name:_that.room_name
					}
				).then(response => {
					if(response.data.errno == 0){
						if(this.rooms.length == 10){
							this.new_disabled = true
						}else{
							this.new_disabled = false
						}
						this.visible = false;
						this.confirmLoading = false;
						// this.getRooms()
						this.rooms.unshift({
							'id':response.data.re,
							'room_name':_that.room_name,
							'room_type_id':this.select_type,
							'room_type':{
								'type_avatar':this.select_type == 1 ? this.type_avatar_1 : (this.select_type == 2 ? this.type_avatar_2 : this.type_avatar_3)
							}
						})
						this.clearMessage(response.data.re)
						return _that.$router.push({ name:'Dashboard',query:{room_id:response.data.re,room_type:this.select_type} })
					}
				}).catch(error => {
					this.new_disabled = false
					this.visible = false;
					this.confirmLoading = false;
				})
			}, 
			handleCancel(e) {
				console.log('Clicked cancel button');
				this.visible = false;
			},
			deleteRoom(room_id){
				axios.delete(
					'http://ai.tool.winndoo.cn/api/room', 
					{
						data:{
							room_id:room_id
						}
					},
				).then(response => {
					if(response.data.errno == 0){
						this.getRooms(true)
					}
				}).catch(error => {
				})
			},
			logout(){
				localStorage.removeItem("user_info");
				return this.$router.push({ name:'Sign-In' })
			},
			initRoom(room_id){
				this.clearMessage(room_id)
			},
			geyRoomType(){
				let user_info = localStorage.getItem('user_info')
				if(!user_info) return
				user_info = JSON.parse(user_info)
				axios.get(
					'http://ai.tool.winndoo.cn/api/room_types?user_id='+user_info.user_id
				).then(response => {
					if(response.data.errno == 0){
						this.room_types = response.data.re
					}
				}).catch(error => {
				})
			},
			createRoom(){
				this.visible = true
				this.select_type = 1
			},
			getRooms(delete_is = false){
				let _that = this
				let user_info = localStorage.getItem('user_info')
				if(!user_info) return
				user_info = JSON.parse(user_info)
				axios.get(
					'http://ai.tool.winndoo.cn/api/rooms?user_id='+user_info.user_id
				).then(response => {
					if(response.data.errno == 0){
						if(response.data.re.length == 0){
							_that.createRoom()
						}else{
							this.rooms = response.data.re.reverse()
							if(!this.current_id){
								this.current_id = this.rooms[0].id
								this.select_type = this.rooms[0].room_type_id
								this.initRoom(this.current_id)
							}
							if(delete_is){
								this.current_id = this.rooms[0].id
								this.select_type = this.rooms[0].room_type_id
								this.initRoom(this.current_id)
							}
							if(this.rooms.length == 10){
								this.new_disabled = true
							}else{
								this.new_disabled = false
							}
							return _that.$router.push({ name:'Dashboard',query:{room_id:this.current_id,room_type:this.select_type} })
						}
					}
				}).catch(error => {
				})
			},
			getUseCount(){
				let user_info = localStorage.getItem('user_info')
				if(!user_info) return
				user_info = JSON.parse(user_info)
				axios.get(
					'http://ai.tool.winndoo.cn/api/user/_value_count?user_id='+user_info.user_id
				).then(response => {
					if(response.data.errno == 0){
						this.use_count = response.data.re
					}
				}).catch(error => {
				})
			}
		},
		mounted: function(){
			let user_info = localStorage.getItem('user_info')
			user_info = JSON.parse(user_info)
			if(user_info){
				this.userName = user_info.username
				this.getRooms()
				this.getUseCount()
				this.geyRoomType()
			}
		},
		watch: {
			$route: function(to, from) {
				if(this.$route.name == 'Dashboard'){
					this.current_id = this.$route.query.room_id
					this.select_type = this.$route.query.room_type
				}
			}
		}
	})

</script>
